import Vue from 'vue';
import { Event } from '@/types/sports';
import DateTimePicker from './DateTimePicker';
import { VDialog, VSnackbar, VCard, VCardTitle, VCardText, VCardActions, VBtn, VAutocomplete, VListItemTitle } from 'vuetify/lib';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import { nullIfEqual, safeUnixToISO } from './helpers';

const failureSnackbarConfig = (content: JSX.Element | null) => {
  return {
    props: {
      timeout: '5000',
      top: true,
      color: 'red darken-1',
      elevation: '24',
    },
    scopedSlots: {
      default: () => content,
    },
  };
};

const statuses = [
  { value: 'EVENT_DISABLED', text: 'EVENT_DISABLED', disabled: true },
  { value: 'POST_TRADING', text: 'POST_TRADING', disabled: true },
  { value: 'TRADING', text: 'TRADING', disabled: true },
  { value: 'TRADING_LIVE', text: 'TRADING_LIVE', disabled: true },
  'RESULTED',
];

export default Vue.extend({
  props: {
    event: Object as () => Event,
  },

  data() {
    return {
      dialog: true,
      failureSnackbar: false,
      failureMessage: null as JSX.Element | null,
      isUpdating: false,

      status: '',
      endsAt: '',
      finishesAt: '',
      startsAt: '',

      originalValues: null as {
        status: string,
        endsAt: string,
        finishesAt: string,
        startsAt: string,
      } | null,
    };
  },

  methods: {
    showFailure(message: string | string[]) {
      if (_.isArray(message)) {
        this.failureMessage = <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else {
        this.failureMessage = (<div>{message}</div>);
      }
      this.failureSnackbar = true;
    },

    onCancel() {
      this.$emit('cancelled');
    },

    async onSave() {
      try {
        this.isUpdating = true;
        const result =
          this.event.type === 'OUTRIGHT' ? await this.updateOutright() : await this.updateEvent();

        if (result) {
          this.$emit('saved');
        }
      } catch (e) {
        if (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].extensions?.response?.body?.errors) {
          this.showFailure(e.graphQLErrors[0].extensions.response.body.errors);
        } else {
          this.showFailure(e.message);
          throw e;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async updateEvent() {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($eventId: Int, $status: String, $startsAt: String) {
            updateEvent(eventId: $eventId, status: $status, startsAt: $startsAt) {
              status
              startsAt
            }
          }`,
        variables: {
          eventId: this.event.id,
          status: nullIfEqual(this.status, this.originalValues?.status),
          startsAt: safeUnixToISO(nullIfEqual(this.startsAt, this.originalValues?.startsAt)),
        },
      });

      const errors = [] as string[];
      if (result.data.updateEvent.status) {
        errors.push(`Status has not been updated: ${result.data.updateEvent.status}`);
      }

      if (result.data.updateEvent.startsAt) {
        errors.push(`Cutoff has not been updated: ${result.data.updateEvent.endsAt}`);
      }

      if (errors.length > 0) {
        this.showFailure(errors);
        return false;
      }

      return true;
    },

    async updateOutright() {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($eventId: Int,
                                $status: String,
                                $endsAt: String,
                                $finishesAt: String) {
            updateOutright(eventId: $eventId,
                           status: $status,
                           endsAt: $endsAt,
                           finishesAt: $finishesAt) {
              status
              endsAt
              finishesAt
            }
          }`,
        variables: {
          eventId: this.event.id,
          status: nullIfEqual(this.status, this.originalValues?.status),
          endsAt: safeUnixToISO(nullIfEqual(this.endsAt, this.originalValues?.endsAt)),
          finishesAt: safeUnixToISO(nullIfEqual(this.finishesAt, this.originalValues?.finishesAt)),
        },
      });

      const errors = [] as string[];
      if (result.data.updateOutright.status) {
        errors.push(`Status has not been updated: ${result.data.updateOutright.status}`);
      }

      if (result.data.updateOutright.endsAt) {
        errors.push(`Cutoff has not been updated: ${result.data.updateOutright.endsAt}`);
      }

      if (result.data.updateOutright.finishesAt) {
        errors.push(`Finish time has not been updated: ${result.data.updateOutright.finishesAt}`);
      }

      if (errors.length > 0) {
        this.showFailure(errors);
        return false;
      }

      return true;
    },
  },

  computed: {
    isValid(): boolean {
      if (this.event.type === 'OUTRIGHT') {
        return !!this.status && !!this.endsAt;
      } else {
        return !!this.status && !!this.startsAt;
      }
    },
  },

  mounted() {
    this.status = this.event.status;
    this.endsAt = this.event.startsAt;
    this.finishesAt = this.event.finishesAt;
    this.startsAt = this.event.startsAt;

    this.originalValues = {
      status: this.status,
      endsAt: this.endsAt,
      finishesAt: this.finishesAt,
      startsAt: this.startsAt,
    };
  },

  render() {
    return (
      <VDialog vModel={this.dialog} onInput={this.onCancel} max-width='500' class='market-creator'>
        <VSnackbar {...failureSnackbarConfig(<div>{this.failureMessage}</div>)} vModel={this.failureSnackbar} />
        <VCard elevation={3}>
          <VCardTitle>
            Event editor
          </VCardTitle>
          <VCardText>
            <VAutocomplete
              dense
              items={statuses}
              vModel={this.status}
              return-object={true}
              label='Status'
              outlined />
            {this.event.type !== 'OUTRIGHT' &&
              <div>
                <VListItemTitle class='mb-3'>Cutoff</VListItemTitle>
                <DateTimePicker vModel={this.startsAt} dateFormat='unix' required={true} />
              </div>
            }
            {this.event.type === 'OUTRIGHT' &&
              <div>
                <VListItemTitle class='mb-3'>Cutoff</VListItemTitle>
                <DateTimePicker vModel={this.endsAt} dateFormat='unix' required={true} />
                <VListItemTitle class='mb-3'>Finish time</VListItemTitle>
                <DateTimePicker vModel={this.finishesAt} dateFormat='unix' />
              </div>
            }
          </VCardText>
          <VCardActions>
            <VBtn
              color='success'
              disabled={!this.isValid || this.isUpdating}
              onClick={this.onSave}
              loading={this.isUpdating}>
              Save
            </VBtn>
            <VBtn onClick={this.onCancel} disabled={this.isUpdating}>Cancel</VBtn>
          </VCardActions>
        </VCard>
      </VDialog >
    );
  },
});
